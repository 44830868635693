@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

* {
  font-family: 'Raleway', sans-serif;
  border-radius: 3px;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  width: 100vw !important;
  height: auto !important;
  overflow-x: hidden;
}

code {
  font-family: 'Raleway', sans-serif;
}

.layout {
  overflow-x: hidden;
  min-height: 100vh !important;
}

.ant-layout-content {
  display: flex;
  background: white;
  justify-content: center;
}

.ant-menu {
  border-radius: 10px !important;
}

.ant-menu-item {
  font-size: 18px !important;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 60px !important;
  line-height: 60px !important;
}