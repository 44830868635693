.layout__header {
  background: #fff !important;
  font-weight: bold;
}

.layout__header__logo {
  float: left;
  height: 100%;
}

.layout__header__logo__text {
  height: 50%;
}

.close {
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 100px;
  font-weight: bold;
  right: 9%;
  top: 5ch;
  color: white;
}

.offerWrapper {
  position: fixed;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  padding: 10ch 10%;
}

.subHeader {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 96px;
  gap: 1ch;
}

.offerWrapperContent {
  height: 100%;
  display: flex;
  background: white;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.offerWrapperContentImage {
  display: flex;
  align-content: center;
  width: 50%;
  background: black;
}

.offerWrapperContentText {
  width: 50%;
  padding: 2ch;
  overflow: auto;
}

.content__body {
  min-height: 280px;
  padding: 145px;
  background: #fff;
}

.content__body__bomber {
  font-size: 64px;
  line-height: 1.25;
  display: flex;
  max-width: 1325px;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.content__body__graphic {
  display: flex;
}

.content__body__graphic__screenshot {
  height: 540px;
  border-radius: 20px;
  padding: 5px;
  margin: 10px;
}

.content__body__features {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1325px;
  margin: auto
}

.content__body__features__title {
  font-size: 64px;
  font-weight: bold;
}

.content__body__features__detail {
  font-size: 64px;
}

.element {
  margin-bottom: 5vh;
}

.content__body__features__element1 {
  margin-right: auto;
  border-right: 2px dotted #ffad4e;
  padding-right: 40px;
}

.title1 {
  width: 550px;
  text-align: right;
}

.block1 {
  text-align: right;
  width: 550px;
  font-size: 24px;
}

.content__body__features__element2 {
  margin-left: auto;
  border-left: 2px dotted #ffad4e;
  padding-left: 38px;
}

.title2 {
  width: 695px;
}

.block2 {
  text-align: left;
  width: 695px;
  font-size: 24px;
}

.test {
  text-align: center
}

.content__body__features__element3 {
  margin-right: auto;
  border-right: 2px dotted #ffad4e;
  padding-right: 40px;
}

.title3 {
  width: 550px;
}

.block3 {
  text-align: right;
  width: 550px;
  font-size: 24px;
}

.content__body__features__element4 {
  font-size: 32px;
  width: 100%;
  text-align: center;
}

.ant-space-item {
  width: 100%;
}

.content__body__bomber__additional-text {
  font-size: 24px;
  margin-top: 10px;
}

.feature-wrap.icons {
  display: flex;
  margin: 0px -145px -145px -145px
}

.black-text {
  color: white;
  font-weight: bold;
  font-size: x-large;
}

.feature-wrap.icons p {
  text-align: center;
  padding: 5ch;
}

.feature-content.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.feature-content.vendorInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}


.feature-content.icons img {
  margin-bottom: 10px;
  filter: grayscale(1);
  height: 55px;
  object-fit: scale-down;
}

.lock__icon {
  border: 5px solid;
  width: fit-content;
  border-radius: 100%;
  padding: 10px;
  color: #ffad4e7a;
  margin: auto;
  margin-bottom: 20px;
}

.password_reset__form {
  margin: auto;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 25px;
}

@media (min-width: 1024px) {
  .password_reset__form {
    max-width: 350px;
  }
}

@media (max-width: 1339px) {
  .qrCodeDetails {
    flex-direction: column;
  }

  .close {
    top: 10px;
  }

  .offerWrapper {
    padding: 4ch 10%;
  }

  .offerWrapperContent {
    flex-direction: column;
  }

  .offerWrapperContentImage {
    width: 100%;
    height: 50%;
  }

  .offerWrapperContentText {
    width: 100%;
    height: 50%;
    overflow: auto;
  }

  .feature-wrap.icons {
    flex-direction: column;
    margin: -10px 0px 0px -20px;
    justify-content: center;
    align-items: center;
  }

  .subHeader {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .content__body__bomber {
    font-size: 34px;
  }

  .content__body__graphic__screenshot {
    height: 430px;
  }

  .content__body__download__play {
    width: 180px !important;
  }

  .content__body__download__apple {
    width: 180px !important;
  }

  .test3 .content__body__bomber>div:not(.content__body__graphic)>img {
    height: 35px !important;
  }

  .content__body__features__title {
    font-size: 42px;
    font-weight: bold;
  }

  .content__body__features__detail {
    font-size: 24px;
  }

  .title1,
  .title3 {
    text-align: right;
  }

  .content__body__bomber__additional-text {
    font-size: 18px;
  }
}

@media (max-width: 1132px) {
  .content__body__download__play {
    margin-bottom: 10px;
  }
}

@media (max-width: 1023px) {
  .content__body {
    padding: 10px 20px 50px 20px;
    width: 100%;
  }

  .content__body__element {
    flex-direction: column;
  }

  .ant-layout-header {
    padding: 10px !important;
  }

  .reverse-container {
    flex-direction: column-reverse;
    display: flex;
  }

  .content__body__bomber {
    font-size: 48px;
    width: auto;
    line-height: 1.25;
    text-align: center;
    flex-direction: column;
  }

  .content__body__graphic {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .content__body__graphic__screenshot {
    max-width: 83vw;
    height: auto;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px !important;
  }

  .content__body__download__play {
    width: 82vw !important;
    margin: 0 !important;
    max-width: 385px !important;
  }

  .content__body__download__apple {
    width: 82vw !important;
    margin-top: 10px;
    max-width: 385px !important;
  }

  .ant-space-align-center {
    max-width: 100% !important;
  }

  .content__body__features__title {
    font-size: 32px;
    font-weight: bold;
  }

  .content__body__features__detail {
    font-size: 24px;
  }

  .content__body__features__element1 {
    margin-right: auto;
    border-bottom: 2px dotted #ffad4e;
    padding-right: 0;
    padding-bottom: 40px;
    border-right: 0;
  }

  .title1 {
    width: auto;
    text-align: center;
  }

  .block1 {
    width: auto;
    font-size: 24px;
    text-align: justify;
    padding-right: 20px;
  }

  .content__body__features__element2 {
    margin-right: auto;
    border-bottom: 2px dotted #ffad4e;
    padding-left: 0;
    padding-bottom: 40px;
    border-left: 0;
  }

  .title2 {
    width: auto;
    text-align: center;
  }

  .block2 {
    text-align: justify;
    padding-right: 20px;
    width: auto;
    font-size: 24px;
  }

  .content__body__features__element3 {
    margin-right: auto;
    border-bottom: 2px dotted #ffad4e;
    padding-right: 0;
    padding-bottom: 40px;
    border-right: 0;
  }

  .title3 {
    width: auto;
    text-align: center;
  }

  .block3 {
    text-align: center;
    width: auto;
    font-size: 24px;
  }

  .content__body__features__element4 {
    margin-right: auto;
    border-bottom: 2px dotted #ffad4e;
    padding-right: 0;
    padding-bottom: 40px;
    border-right: 0;
  }

  .ant-layout-header {
    height: 140px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .layout__header__logo {
    height: 100%;
    margin-left: auto;
    padding-top: 20px;
  }

  .layout__header__logo__img {
    height: auto;
    max-width: 77px;
    padding-left: 10px;
  }

  .layout__header__logo__text {
    height: auto;
    max-width: 180px;
  }

  .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
    margin-left: auto !important;
  }

  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    font-size: 24px !important;
  }

  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    opacity: 0 !important;
    height: 0px !important;
    overflow-x: hidden !important;
    pointer-events: none !important;
    position: absolute !important;
  }

  .ant-menu-horizontal {
    border-bottom: 0 !important;
  }
}

@media (max-width: 340px) {
  .layout__header__logo__img {
    height: auto;
    max-width: 70px;
    padding-left: 10px;
  }

  .layout__header__logo__text {
    height: auto;
    max-width: 160px;
  }
}